<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <el-form-item label="商品类型">
          <el-select
            v-model="queryForm.goods_type"
            placeholder="请选择商品类型"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in goodsTypeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input
            placeholder="请输入商品名称"
            v-model="queryForm.goods_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加商品</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="integralGoodsList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_type"
          label="商品类别"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.goods_type=='goods'?'实物商品':'抵扣券'}}
          </template>
        </el-table-column>
        <el-table-column label="缩略图" prop="thumb" align="center" #default="scope" width="220">
          <div class="desc">
            <img :src="scope.row.thumb" alt="">
          </div>
        </el-table-column>
        <el-table-column label="商品名称" prop="goods_name" align="center"></el-table-column>
        <el-table-column label="兑换积分" prop="price" align="center">
        </el-table-column>
        <el-table-column label="库存数量" prop="stock" align="center">
        </el-table-column>
        <el-table-column label="有效期" align="center">
          <template slot-scope="scope">
            <div>{{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}-</div>
             <div>{{$moment(scope.row.expire * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}}</div>
          </template>
        </el-table-column>
        <el-table-column label="上架状态" prop="is_enable" align="center">
             <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.is_enable == 1"
              @click="editState(scope.row.id, 0)"
            >
              上架
            </el-tag>
            <el-tag type="danger" v-else @click="editState(scope.row.id, 1)"
              >下架
            </el-tag>
          </template>
        </el-table-column>
       
   
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row.id)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeGoods(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加积分商品 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">

         <el-form-item el-form-item label="商品分类" label-width="100px">
          <el-select
            v-model="addForm.goods_type"
            placeholder="请选择商品类型"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in goodsTypeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item el-form-item label="商品名称" label-width="100px">
            <el-input v-model="addForm.goods_name" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="商品价格" label-width="100px">
            <el-input type="number" v-model="addForm.price" @input="changeInput('price')" placeholder="请输入商品价格"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="商品库存" label-width="100px">
            <el-input type="number" v-model="addForm.stock" @input="changeInput('stock')" placeholder="请输入商品库存"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="兑换人数" label-width="100px">
            <el-input type="number" v-model="addForm.buy_num_vt" @input="changeInput('buy_num_vt')" placeholder="请输入兑换人数"></el-input>
        </el-form-item>
        <el-form-item  el-form-item label="上架状态" label-width="100px">
          <el-select
            v-model="addForm.is_enable"
            placeholder="请选择上架状态"
          >
            <el-option
               v-for="item in enableOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="addForm.goods_type=='coupons'">
          <el-form-item el-form-item label="抵扣面额" label-width="100px">
            <el-input type="number" v-model="addForm.cash" @input="changeInput('cash')" placeholder="请输入抵扣面额"></el-input>
        </el-form-item>
        
        <el-form-item el-form-item label="使用条件" label-width="100px">
            <el-input type="number" v-model="addForm.condition" @input="changeInput('condition')" placeholder="请输入使用条件数字"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="到期时间" label-width="100px">
            <el-date-picker
              v-model="expire"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
        </el-form-item>
        </template>
        <el-form-item el-form-item label="缩略图" label-width="100px">
           <el-upload
            class="avatar-uploader"
            action="123"
            :show-file-list="false"
            accept="image/*"
            :before-upload="beforeThumbUpload"
          >
            <img
              v-if="thumbSrc"
              style="width: 100%"
              :src="thumbSrc"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              style="margin-top: 85px;"
            ></i>
          </el-upload>
        </el-form-item>

        <el-form-item el-form-item label="商品介绍" label-width="100px">
        </el-form-item>
        <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
        </quill-editor>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
import store from "@/store";
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
  IntegralGoods,
  IntegralGoodsEnable,
  getintegralCosToken,
  IntegralGoodsAdd,
  IntegralGoodsDel,
  IntegralGoodsDetails,
  IntegralGoodsEdit,
} from "@/api/points_mall.js";

import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        goods_name: "",
        goods_sn: "",
        goods_type: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      goodsTypeArr: [
        { key: "goods", value: "实物商品" },
        { key: "coupons", value: "抵扣券" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      integralGoodsList: [],
      addForm: {
        goods_name:"",
        goods_type: "",
        thumb:"",
        content:"",
        price:null,
        cash:null,
        buy_num_vt:null,
        stock:null,
        expire:"",
        condition:null,
        is_enable:""
      },
      expire:'',
      enableOptions: [
        { value: 1, label: "上架" },
        { value: 0, label: "下架" },
      ],
      addtitle: "",
      isAdd: "add",
      thumbSrc: "",
    };
  },
  created() {
    this.getIntegralGoods();
  },
  methods: {
    //   获取商品列表
    getIntegralGoods() {
      IntegralGoods(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.integralGoodsList = res.data.data;
      });
    },
    //   修改上架状态
    editState(id, index) {
      let data = {
        id: id,
        is_enable: index,
      };
      IntegralGoodsEnable(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改上架状态成功!");
        this.getIntegralGoods();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加商品";
      this.addDialog = true;
    },
    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getintegralCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 添加商品
    addNow() {
      this.addForm.price=Number(this.addForm.price)
      this.addForm.buy_num_vt=Number(this.addForm.buy_num_vt)
      this.addForm.stock=Number(this.addForm.stock)
      this.addForm.condition=Number(this.addForm.condition)
      this.addForm.cash=Number(this.addForm.cash)
      this.addForm.expire=new Date(this.expire).getTime() / 1000;
      if (this.addForm.goods_type == "") {
        this.$message.error("请选择商品分类");
        return;
      } else if (this.addForm.goods_name == "") {
        this.$message.error("请填写商品名称");
        return;
      } else if (this.addForm.price == null) {
        this.$message.error("请输入商品价格");
        return;
      } else if (this.addForm.stock == null) {
        this.$message.error("请输入商品库存");
        return;
      } else if (this.addForm.buy_num_vt == null) {
         console.log('兑换人数',this.addForm.buy_num_vt)
        this.$message.error("请输入兑换人数");
        return;
      } else if (this.addForm.is_enable == "") {
        this.$message.error("请选择上架状态");
        return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.cash == null) {
        this.$message.error("请输入抵扣面额");
        return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.condition == null) {
          this.$message.error("请填写使用条件");
          return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.expire == "") {
        this.$message.error("请选择到期时间");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请填写商品介绍");
        return;
      }
      
      IntegralGoodsAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加商品成功");
        this.addDialog = false;
        this.getIntegralGoods();
      });
    },
    // 删除商品
    async removeGoods(id) {
      const result = await this.$confirm("是否要删除该商品?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      IntegralGoodsDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除商品成功");
        this.getIntegralGoods();
      });
    },

    // 点击编辑图标
    editGoods(id) {
      this.isAdd = "edit";
      this.addtitle = "修改商品";
      this.addDialog = true;
      IntegralGoodsDetails({ id: id }).then((res) => {
        if (res.code == 1) {
          this.expire=res.data.expire*1000
          if (res.data.expire==0) {
            this.expire=""
          }
          this.addForm = res.data;
          this.thumbSrc = res.data.thumb_url;
        }
      });
    },

    // 点击立即修改
    editReadNow() {
      this.addForm.price=Number(this.addForm.price)
      this.addForm.buy_num_vt=Number(this.addForm.buy_num_vt)
      this.addForm.stock=Number(this.addForm.stock)
      this.addForm.condition=Number(this.addForm.condition)
      this.addForm.cash=Number(this.addForm.cash)
      this.addForm.expire=new Date(this.expire).getTime() / 1000;
      if (this.addForm.goods_type == "") {
        this.$message.error("请选择商品分类");
        return;
      } else if (this.addForm.goods_name == "") {
        this.$message.error("请填写商品名称");
        return;
      } else if (this.addForm.price == null) {
        this.$message.error("请输入商品价格");
        return;
      } else if (this.addForm.stock == null) {
        this.$message.error("请输入商品库存");
        return;
      } else if (this.addForm.buy_num_vt == null) {
        this.$message.error("请输入兑换人数");
        return;
      } else if (this.addForm.is_enable == "") {
        this.$message.error("请选择上架状态");
        return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.cash == null) {
        this.$message.error("请输入抵扣面额");
        return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.condition == null) {
          this.$message.error("请填写使用条件");
          return;
      } else if (this.addForm.goods_type=='coupons'&&this.addForm.expire == "") {
        this.$message.error("请选择到期时间");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请填写商品介绍");
        return;
      }

      let editData = {
        id: this.addForm.id,
        goods_type:this.addForm.goods_type,
        goods_name: this.addForm.goods_name,
        thumb: this.addForm.thumb,
        content: this.addForm.content,
        price: this.addForm.price,
        stock: this.addForm.stock,
        is_enable: this.addForm.is_enable,
        cash:this.addForm.cash,
        buy_num_vt:this.addForm.buy_num_vt,
        condition:this.addForm.condition,
        expire:this.addForm.expire,
      };
      IntegralGoodsEdit(editData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改商品成功!");
        this.addDialog = false;
        this.getIntegralGoods();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm={
        goods_name:"",
        goods_type: "",
        thumb:"",
        content:"",
        price:null,
        cash:null,
        buy_num_vt:null,
        stock:null,
        expire:"",
        condition:null,
        is_enable:""
      },
      this.expire=""
      this.thumbSrc = "";
    },

    // 价格及库存只能输入正整数
    changeInput(target) {
      if (target=='price') {
        if (Number(this.addForm.price) <= 0) {
          this.$message.error("价格不能小于等于零哦");
          this.addForm.price = null;
          return;
        }
      }else if (target=='stock') {
        if (Number(this.addForm.stock) < 0) {
          this.$message.error("库存不能小于零哦");
          this.addForm.stock = null;
          return;
        }
      }else if (target=='buy_num_vt') {
        if (Number(this.addForm.buy_num_vt) < 0) {
          this.$message.error("兑换人数不能小于零哦");
          this.addForm.buy_num_vt = null;
          return;
        }
      }else if (target=='cash') {
        if (Number(this.addForm.cash) <= 0) {
          this.$message.error("抵扣面额不能小于等于零哦");
          this.addForm.cash = null;
          return;
        }
      }else if (target=='condition') {
        console.log(Number(this.addForm.condition))
        if (Number(this.addForm.condition) < 0) {
          this.$message.error("使用条件不能小于零哦");
          this.addForm.condition = null;
          return;
        }
      }
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getIntegralGoods();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getIntegralGoods();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getIntegralGoods();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box {
  height: 750px;
}
.detail {
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img {
  flex: 2;
}
.detail-img img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one {
  flex: 4;
}
.detail .detail-two {
  flex: 5;
}
.detail .detail-three {
  flex: 3;
}
.detail p {
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs {
  border-bottom: 2px solid #38a28a;
}
.detail-box .tabs .el-button {
  width: 160px;
  height: 50px;
  border-radius: 0;
}
.detail-box .detail-content {
  height: 504px;
  background-color: #f0f0f0;
}
.jurisdiction {
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content {
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm {
  text-align: center;
  height: 100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button {
  width: 90px;
  height: 40px;
}
.desc {
  width: 180px;
  height: 112px;
  overflow: hidden;
}
.desc img {
  width: 100%;
  height: 100%;
}
.generate {
  float: right;
}
.file-name {
  /* display: inline-block; */
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.imgSelect img {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
.videoSelect video {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

::v-deep .ql-editor {
  min-height: 400px;
}
</style>

import request from "@/utils/request";

// 获取积分商城列表
export function IntegralGoods(params){
    return request({
        url:'/admin/base/goldGoodsList',
        method: 'get',
        params
        }
    )
}

// 修改商品上架状态
export function IntegralGoodsEnable(data){
    return request({
        url:'/admin/base/goldGoodsEnable',
        method: 'put',
        data
        }
    )
}

// 获取商品上传签名
export function getintegralCosToken(params){
    return request({
        url:'/admin/base/goldCosToken',
        method: 'get',
        params
        }
    )
}

// 商品添加
export function IntegralGoodsAdd(data){
    return request({
        url:'/admin/base/goldGoodsAdd',
        method: 'post',
        data
        }
    )
}

// 商品删除
export function IntegralGoodsDel(data){
    return request({
        url:'/admin/base/goldGoodsDel',
        method: 'delete',
        data
        }
    )
}

// 获取商品详情
export function IntegralGoodsDetails(params){
    return request({
        url:'/admin/base/goldGoodsDetails',
        method: 'get',
        params
        }
    )
}

// 修改商品
export function IntegralGoodsEdit(data){
    return request({
        url:'/admin/base/goldGoodsEdit',
        method: 'put',
        data
        }
    )
}